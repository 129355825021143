const SellingPointCard = ({title,text}) => {
    return (
        <div className="bg-white rounded-lg  p-3 md:p-6">
            <h2 className="text-2xl font-semibold text-center">{title}</h2>
            <p className="text-gray-600">
              {text}
            </p>
        </div>
    );
}
 
export default SellingPointCard;