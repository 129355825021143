import { Fragment } from "react";
import { EnvelopeFill } from "react-bootstrap-icons"

const Contact = () => {
    return (
        <Fragment>
            <div className="w-full flex flex-row justify-center my-7 pt-4" id="kapcsolat">
                
                <a href="mailto:szalagoslegycsapo@gmail.com" className=" py-4 px-8 bg-tyellow text-tblack rounded-lg md:text-lg hover:scale-95 active:scale-[90%] transition-all duration-100 flex flex-row items-center justify-between w-3/4 md:w-auto">
                    <p>
                        <EnvelopeFill/>
                    </p>
                    <p className=" mx-3 text-ellipsis overflow-hidden">
                    szalagoslegycsapo@gmail.com
                    </p>
                </a>
            </div>
        <div className=" w-full flex justify-center">
                <img src="/osszehas.png" className="sm:w-1/2 md:w-1/3 px-4" alt=""/>
        </div>
        </Fragment>
    );
}
 
export default Contact;