import SellingPointCard from "./SellingPointCard";

const SellingPoints = () => {
    return (
        <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-4" id="tulajdonsagok">
          <SellingPointCard title="Teljesen megújult" text="
            A hagyományos légycsapók (hosszú fa vagy műanyag nyél+kerekded, 
            lapos műanyag vagy bőr csapófelület) általános, megszokott „képletével” 
            szemben ennél az eszköznél egy rövid nyél keskeny, hosszú bőrszalagok tucatjaiban végződik.
            Mit eredményez az új szerkezet és forma? Hatékonyabb, sokoldalúbb, komfortosabb használhatóságot."
          />
          <SellingPointCard title="Több tucat szalag" text="
             Mikor csapunk vele, akkor ezek a bőrszalagok lendülnek
             mozgásba és szétterülve a térben még nagyobb felületen,
             több irányból érkezve sújtanak le, alig hagyva esélyt
             a rovarnak a túlélésre."
          />
          <SellingPointCard title="Könnyebb célzás" text="
            Mivel a Szalagos® légycsapó körszerű keresztmetszetű 
            (ellentétben a többi, nyeles-síklapos légycsapóval, 
            ahol figyelnünk kell a „tájolására” használat előtt)
            itt, ennél a légycsapónál teljesen mindegy, hogyan 
            vesszük kézbe, ezért azonnal és könnyen használható. 
            Így az összes figyelmünket a lehető legpontosabb 
            célzásra és a leggyorsabb csapásra irányíthatjuk.
            "
          />
          <SellingPointCard title="Továbbfejlesztett támadás" text="
            Közismert a legyek rendkívüli gyors reagálóképessége 
            a külvilággal szemben. Összetett szemükkel szinte 
            hátrafelé is látnak, emellett a testüket borító 
            finom szőrszálaknak köszönhetően a legkisebb 
            légmozgást is azonnal érzékelik, így ki tudják 
            „kalkulálni” a támadás irányát. A Szalagos® 
            légycsapó nemcsak pl. a falon mászkáló légyre 
            veszélyes, hanem akkor is, amikor az a levegőbe
             emelkedik. Ilyenkor nem egy célt tévesztett 
             bőr- vagy műanyag lap suhan el mellette, hanem 
             egy különböző irányokból, eltérő pillanatokban 
             érkező vékony szalagok tucatjaival „felfegyverzett” 
             légycsapó ütése fogadja.
            "
          />
          <SellingPointCard title="Autóba is tökéletes" text="
            Fentebb vázolt jellemzői, rövid nyele miatt 
            autók belterében is nagy hasznunkra lehet. 
            (Gondoljunk csak a szélvédő és a műszerfal szegletében 
            repdeső, a vezető figyelmét elterelő, megzavaró 
            légyre, darázsra, vagy az utasérbe berepülő más 
            egyéb rovarra.) Épp ezért a balesetmegelőzés, a 
            közlekedésbiztonság szempontjából is figyelemre méltó.
            "
          />
          <SellingPointCard title="Üvegen is használható" text="Mivel a légycsapót 
            döntően puha bőrszalagok alkotják, ezért ablaküvegek, 
            tükrök, képernyők, monitorok felületein is gond nélkül, 
            a törés, repedés veszélyét minimálisra csökkentve 
            használhatjuk.
            "
          />
          <SellingPointCard title="Szinte minden felületen hatásos" text="
          A szalagos szerkezetből következik az is, hogy
          a légycsapó nem csak sík, hanem egyenetlen felületen, 
          pl. ablakpárkányok mélyedéseiben, egyéb zugokban is 
          hatékonyan használható. A nyeles-síklapos légycsapóval 
          szemben a szalagok az üreges részekbe is könnyen 
          behatolnak. Túlzás lenne állítani, hogy 100/100, 
          azaz száz csapásból száz találat…Azt viszont bátran 
          állíthatjuk: a szalagok elől csak elvétve, nagyritkán 
          van menekvés.
          "
          />
        </div>
      </div>

    );
}
 
export default SellingPoints;