import { Fragment } from "react";

const Footer = () => {
    return (
        <Fragment>
            <div className="bg-tblack mt-4 text-twhite p-10">
                    <div className="flex flex-row justify-evenly">
                       {/* <div>
                            <h3 className=" font-semibold">E-mail:</h3>
                            <a href="mailto:szalago@gmail.com">dessin96@gmail.com</a>
                        </div>
                        <div>
                            <h3 className="font-semibold">Telefon:</h3>
                            <a href="tel:+">+36??</a>
                        </div> */}
                    </div>
                <p className=" w-full text-center mt-5">©2024 Tóth Lajos</p>
                
            </div>
        </Fragment>
    );
}
 
export default Footer;