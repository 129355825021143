const NavBar = () => {
    return (
        <nav className="w-full flex flex-col md:flex-row justify-evenly py-2">
            <img src="/logo.png" alt="" className="h-16 object-contain" />
            <div className="flex flex-col md:flex-row items-center gap-3">
                <a className="uppercase hover:scale-105 transition-all duration-75 mt-5 md:mt-0" href="#tulajdonsagok">Mitől új és más?</a>
                <a className="uppercase hover:scale-105 transition-all duration-75" href="#kapcsolat">Kapcsolat</a>
            </div>
        </nav>
    );
}

export default NavBar;
