import Contact from "./Contact";
import Footer from "./Footer";
import Hero from "./Hero";
import NavBar from "./NavBar";
import SectionHeader from "./SectionHeader";
import SellingPoints from "./SellingPoints";

function App() {
  return (
    <div className=" text-tblack">
      <NavBar/>
      <Hero/>
      <Contact />
      <SectionHeader header="Mitől új és más?"/>
      <SellingPoints/>
      <Footer/>
    </div>
  );
}

export default App;
