const Hero = () => {
    return (
        <div className="w-full h-full flex flex-col md:flex-row justify-center items-center gap-10 py-14 bg-tyellow">
            <div className="mx-5 md:w-1/4 h-full place-self-center">
                <h1 className="uppercase text-4xl md:text-5xl leading-snug"><span className=" font-semibold text-5xl md:text-7xl">Légycsapó</span><br/>Újragondolva</h1>
                <p className=" mt-5 text-lg">
                    A legyek és más egyéb apró rovarok elleni küzdelemben a hagyományos,
                    a boltokban általában kapható légycsapókhoz képest kínál alapjában 
                    új és hatékony megoldást a
                    Szalagos® légycsapó.
                </p>
            </div>
            <div className="mx-5 w-screen md:w-1/4 flex flex-row justify-center">
                <div className="md:w-full md:h-full w-2/3 h-2/3 ">
                    <img src="/legy-athuzva.png" className="" alt=""/>
                </div>
            </div>
        </div>
    );
}
 //<img src="/test.png" className="object-scale-down" alt=""/>
export default Hero;